<template>
  <div id="app" style="height: 100vh" :class="[skinClasses]">
    <!--  Load layout based on themeConfig.js, manage by store -->
    <!-- <pre>contentLayoutType: {{ contentLayoutType }}</pre>
    <div v-if="isLoading">
      <b-spinner class="d-block mx-auto mt-3" />
    </div> -->
    <!-- <component v-else :is="layout"> -->
    <!-- <router-view /> -->
    <!-- </component> -->

    <!-- VIEW -->
    <router-view />

    <!-- Global Components (chat sidebar) -->
    <GlobalComponents />

    <!-- Cookies Banner -->
    <BannerCookies />

    <!-- GDPR Banner -->
    <GDPRModal />
  </div>
</template>

<script>
// import Vue from 'vue';
// import { BSpinner } from 'bootstrap-vue';
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { provideToast } from 'vue-toastification/composition';
import { watch } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';

import enabledAppsStoreModule from '@/store/enabled-apps/enabledAppsStoreModule';
import { ENABLED_APPS_STORE_MODULE_NAME } from '@/store/enabled-apps/enabled-apps-store-constants';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';
// import { isUserLoggedIn } from '@/auth/utils';

// // SocketIO
// import VueSocketIO from 'vue-socket.io';
// import SocketIO from 'socket.io-client';

import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';

// import GlobalComponents from '@core/components/global-components/GlobalComponents.vue';
// //Cookies
// import BannerCookies from "@/@core/components/BannerCookies.vue";
// //GDPR
// import GDPRModal from "@/@core/components/GDPRModal.vue";

// LAYOUTS
// const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
// const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue');
// const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');

// import { defineAsyncComponent } from '@vue/composition-api';

export default {
  name: 'App',

  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');
    if (skin.value === 'landing') document.body.classList.add('landing-layout');
    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    return {
      skinClasses,
    };
  },

  components: {
    // BSpinner,
    // GlobalComponents,
    // BannerCookies,
    // GDPRModal
    GlobalComponents: () => import(
      '@core/components/global-components/GlobalComponents.vue'
      /* webpackChunkName: "GlobalComponents" */
    ),
    BannerCookies: () => import('@/@core/components/BannerCookies.vue' /* webpackChunkName: "BannerCookies" */),
    GDPRModal: () => import('@/@core/components/GDPRModal.vue' /* webpackChunkName: "GDPRModal" */),
    // Layouts
    // LayoutVertical,
    // LayoutHorizontal,
    // LayoutFull,
  },

  mixins: [GridStatusMixin],

  async beforeCreate() {
    // This store module needs to be initialized here, as it's used in the AppBreadcrumb,
    // whichs is outside of the routing structure.
    if (!this.$store.hasModule(ENABLED_APPS_STORE_MODULE_NAME)) {
      this.$store.registerModule(
        ENABLED_APPS_STORE_MODULE_NAME,
        enabledAppsStoreModule,
      );
    }

    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ];

    for (let i = 0, len = colors.length; i < len; i += 1) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
    for (let i = 0, len = breakpoints.length; i < len; i += 1) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      );
    }

    // Set RTL (Right to Left)
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },

  // data() {
  //   return {
  //     mountedCommunityId: null,
  //   };
  // },

  computed: {
    currentCollectiveSlug() {
      return this.$store.getters.currentCollective.slug;
    },
    loggedMemberKey() {
      return this.$store.getters.loggedMember.key;
    },
    roomValue() {
     return this.currentCollectiveSlug;
    },
    
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  //   // Load Layout
  //   layout() {
  //     if (this.$route.meta?.layout) return `layout-${this.$route.meta.layout}`;
  //     if (this.contentLayoutType) return `layout-${this.contentLayoutType}`;
  //     return 'layout-vertical';
  //   },
  //   contentLayoutType() {
  //     if (this.isGridDisabled) {
  //       return 'vertical';
  //     }
  //     if (this.$route.path.includes('/backoffice') || this.$route.path.includes('/myself')) {
  //       return 'vertical';
  //     }
  //     if (this.$store.getters.currentCollective?.menu?.layout) {
  //       return this.$store.getters.currentCollective?.menu?.layout;
  //     }
  //     return this.$store.state.appConfig?.layout?.type;
  //   },
  //   isLoading() {
  //     return this.$store.getters['app/loading'];
  //   },
  },

  watch: {
    roomValue(newRoom, oldRoom) {
      console.log('Watching the roomValue...');
      console.log('Room changed from oldRoom:', oldRoom);
      console.log('To newRoom:', newRoom);

      this.joinSocketRoom(newRoom);
    },
  },

  methods: {
    joinSocketRoom(room) {
      if(!room){
        room = this.currentCollectiveSlug;
      }
      console.log('Joining room:', room);
      this.$socket.emit('join', room);
    },
    limitString(str, limit) {
      if (str.length > limit) {
        return str.substring(0, limit) + '...';
      }
      return str;
    }
  },

  // SocketIO
  sockets: {
    // Connect to the socket
    connect() {
     console.log('socket connected')
    },
    // Disconnect from the socket
    disconnect() {
      console.log('socket disconnected')
    },
    // Receive message from the socket
    async received(data) {
      console.log('Message received from the socket:', data);

      const currentPath = this.$route.fullPath.startsWith("/") ? this.$route.fullPath.slice(1) : this.$route.fullPath;
      // console.log('currentPath:', currentPath);

      const htmlString = data?.message;
      // console.log('htmlString:', htmlString);

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const innerText = doc.body.textContent.trim();
      // console.log('message received - innerText:', innerText);
      const limitedMessage = this.limitString(innerText, 100);

      const inHomeView = currentPath !== this.currentCollectiveSlug; //TODO: make this better
      const inGroupChat = data?.room === this.currentCollectiveSlug;

      if (inHomeView) {
        // console.log('Message received in a different room than the current one...');
        if (this.loggedMemberKey !== data?.memberData?.key) {
          // console.log('Message received from a different member than the current one...');
          // Show toast notification
          // const senderFullName = data?.memberData ? data.memberData.name + ' ' + data.memberData.surname : null;
          const title = "New " + (inGroupChat ? 'Group' : 'Private') + ' Chat Message';
          const from = data?.memberData;
          const buttonLink = '/' + this.currentCollectiveSlug + '/' + (inGroupChat ? 'chat' : '?chat-user=' + data?.memberData?.username);
          // const avatarUrl = data?.memberData?.avatarURL;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: title,
              user: from,
              text: limitedMessage,
              buttonLink: buttonLink,
              icon: 'InfoIcon',
              variant: 'info',
              hideClose: true
            },
          });
        } else {
          // console.log('Message received from the same member as the current one...');
        }
      } else {
        // console.log('Message received in the same room as the current one...');
      }
    },
  },

  // async beforeUpdate() {
  //   // Are we in a community or in My Nectios?
  //   const routeCommunityId = this.$route?.params?.communityId;
  //   const insideCommunity = isUserLoggedIn() && this.$store.getters['app/empty'] && routeCommunityId;
  //   //If we are in a community, get its installed apps --> REVIEW!
  //   if (insideCommunity) {
  //     if (this.mountedCommunityId !== routeCommunityId) {
  //       this.mountedCommunityId = routeCommunityId;
  //       // Get installed Apps:
  //       await this.$store.dispatch('getItems', {
  //         itemType: 'apps',
  //         page: 1,
  //         communitySlug: routeCommunityId || this.$route.params.communitySlug,
  //       });
  //     }
  //     // Start Application
  //     this.$store.dispatch('app/startApp');
  //   }
  // },
};
</script>
